// extracted by mini-css-extract-plugin
export var customDescription = "settingsItem-module--customDescription--UPnhU";
export var customDescriptionBefore = "settingsItem-module--customDescription-before--TS+em";
export var settingsItemDescription = "settingsItem-module--settingsItem-description--wZt6l";
export var settingsItemDescriptionBefore = "settingsItem-module--settingsItem-description-before--En5mx";
export var settingsItemFields = "settingsItem-module--settingsItem-fields--w0erL";
export var settingsItemInput = "settingsItem-module--settingsItem-input--Iktu9";
export var settingsItemInputWide = "settingsItem-module--settingsItem-input-wide--9zYw-";
export var settingsItemToggle = "settingsItem-module--settingsItem-toggle--0f2yi";
export var settingsItemToggleInfo = "settingsItem-module--settingsItem-toggleInfo--yBs7J";
export var settingsItemToggleWrapper = "settingsItem-module--settingsItem-toggleWrapper--3+sl1";
export var settingsItemWrapper = "settingsItem-module--settingsItem-wrapper--uUN5S";