import React from 'react'

import Settings from '../../components/views/profile/settings'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function AccountSettingsPage() {
   return (
      <LayoutLoggedIn>
         <Settings />
      </LayoutLoggedIn>
   )
}
