import React from 'react';
import classNames from 'classnames';

import { Input, Textarea, Toggle, ToggleInfo } from "../../form/fields";

import { Props } from './settingsItem.type';
import * as styles from './settingsItem.module.scss';

const SettingsItem: React.FC<Props> = props => {
    const {
        customField,
        description,
        descriptionLocation = 'after',
        error,
        id,
        isEnabled,
        label,
        onChangeCustomField,
        onToggle,
    } = props;

    const handleCustomFieldChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        const { name, value } = event.target;
        onChangeCustomField(name, value);
    };

    const renderFormField = () => {
        switch(customField.type) {
            case 'text':
            case 'number':
                return (
                    <Input
                        id={customField.key}
                        label={customField.label}
                        onChange={handleCustomFieldChange}
                        required
                        value={customField.value}
                        error={error}
                        inputProps={{
                            type: customField.type
                        }}
                        noMargin
                    />
                );

            case 'textarea':
                return (
                    <Textarea
                        id={customField.key}
                        label={customField.label}
                        onChange={handleCustomFieldChange}
                        required
                        value={customField.value}
                        error={error}
                        noMargin
                    />
                );

            default:
                console.error(`Unrecognised field type: ${customField.type}`);
                return null;
        }
    }

    const renderCustomText1Desc = () => <div className={styles.customDescription}>When enabled this field shows up on the Item Card and Grid views, and is searchable by users.</div>;

    const renderDescription = () =>
        <div className={classNames(
            styles.settingsItemDescription,
            {
                [styles.settingsItemDescriptionBefore]: descriptionLocation === 'before',
            }
        )}>{description}</div>

    return (
        <div className={styles.settingsItemWrapper}>
            {!!description && descriptionLocation === 'before' && renderDescription()}
            <div className={styles.settingsItemFields}>
                <div className={styles.settingsItemToggleWrapper}>
                    <div className={styles.settingsItemToggle}>
                        <Toggle
                            id={id}
                            size="md"
                            isChecked={isEnabled}
                            onToggle={onToggle}
                        />
                    </div>
                    <div className={styles.settingsItemToggleInfo}>
                        <ToggleInfo
                            name={label}
                            value={isEnabled}
                        />
                    </div>
                </div>
                {customField && isEnabled && (
                    <div className={classNames(
                        styles.settingsItemInput,
                        {
                            [styles.settingsItemInputWide]:  customField?.type === 'textarea',
                        }
                    )}>
                        {renderFormField()}
                    </div>
                )}
            </div>
            {id === 'CustomText1Enabled' && renderCustomText1Desc()}
            {!!description && descriptionLocation === 'after' && renderDescription()}
        </div>
    );
}

export default SettingsItem;
