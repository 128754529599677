import React, { useEffect, useState } from 'react'

import { Alert, Button, LoadingOverlay, PageHeader } from '../../common'
import SettingsItem from '../../common/views/settingsItem/settingsItem'
import { useProfile } from '../../../hooks'
import { isEmptyObj } from '../../common/form/helper'
import { Severity } from '../../../types'

const Settings = () => {
   const { isSaving, isSuccess, profile, updateProfileSettings } = useProfile()
   const [values, setValues] = useState({
      AutoAcceptToolAssignments: profile?.AutoAcceptToolAssignments,
      AllowToolTransferToVirtualConnections:
         profile?.AllowToolTransferToVirtualConnections,
   })

   const onChangeToggle = (key: string, value: any) => {
      setValues({ ...values, [key]: value })
   }

   const handleSubmit = () => {
      updateProfileSettings(values)
   }

   useEffect(() => {
      if (!isEmptyObj(profile)) {
         setValues({
            AutoAcceptToolAssignments: profile?.AutoAcceptToolAssignments,
            AllowToolTransferToVirtualConnections:
               profile?.AllowToolTransferToVirtualConnections,
         })
      }
   }, [profile])

   const profileInfoLoaded = !isEmptyObj(profile)

   return (
      <div>
         <PageHeader title="Account Settings" variant="wideMargin" />

         {profileInfoLoaded ? (
            <>
               <SettingsItem
                  description="If Auto Accept Tool Assignments is enabled, employee connections can borrow or check in tools without admin approval. Enabling will turn this on for all administrators."
                  descriptionLocation="after"
                  id="AutoAcceptToolAssignments"
                  label="Auto Accept Item Assignments"
                  onToggle={onChangeToggle}
                  isEnabled={values?.AutoAcceptToolAssignments}
               />

               <SettingsItem
                  description="If Allow Tool Transfer to Virtual Connections is enabled, admins can transfer tools from employee connections to virtual connections without checking them in first. This generally should remain on."
                  descriptionLocation="after"
                  id="AllowToolTransferToVirtualConnections"
                  label="Allow Item Transfer To Virtual Connections"
                  onToggle={onChangeToggle}
                  isEnabled={values?.AllowToolTransferToVirtualConnections}
               />

               {isSuccess && (
                  <Alert
                     message="Your account settings have been updated successfully"
                     severity={Severity.SUCCESS}
                  />
               )}

               <Button loading={isSaving} onClick={handleSubmit}>
                  Save
               </Button>
            </>
         ) : (
            <LoadingOverlay />
         )}
      </div>
   )
}

export default Settings
