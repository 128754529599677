export const SETTINGS_LABELS = {
    AuditEnabled: 'Audit',
    ConsumablesEnabled: 'Consumables',
    CustomText1Enabled: 'Custom Text 1',
    CustomText2Enabled: 'Custom Text 2',
    CustomDate1Enabled: 'Custom Date 1',
    CustomDate2Enabled: 'Custom Date 2',
    CustomURL1Enabled: 'Custom URL 1',
    CustomURL2Enabled: 'Custom URL 2',
    CustomRentalRate1Enabled: 'Custom Rental Rate 1',
    CustomRentalRate2Enabled: 'Custom Rental Rate 2',
    CustomRentalRate3Enabled: 'Custom Rental Rate 3',
    CustomRentalRate4Enabled: 'Custom Rental Rate 4',
    EnableLocationTracking: 'Location Tracking',
    KitEnabled: 'Kit Items',
    LocationTrackingLabel: 'Label',
    QuantityEnabled: 'Quantity Items',
    RequireTerms: 'Require Terms',
    AllowPushNotifications: 'Receive Notification',
    ForceScanToAccept: 'Force Scan To Accept'
};